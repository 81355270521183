.separator {
	border-bottom: 0.03rem solid #0000004d;
	margin: 0 1.6rem;
}

@media (min-width: 1024) {
	.separator {
		display: none;
	}
}
