.separator {
  border-bottom: .03rem solid #0000004d;
  margin: 0 1.6rem;
}

@media (width >= 1024px) {
  .separator {
    display: none;
  }
}
/*# sourceMappingURL=index.69a00ac9.css.map */
